// css
import "../css/SliderInput.css";

// component
import Slider from "@mui/material/Slider";

const SliderInput = ({ min = 0, max = 10, value = 0, onChange }) => {
  if (max-min < 1) {
    throw new Error("SliderInput: max must be greater than min");
  }
  const marks = Array.from({length:max-min+1},(v,k)=>k).map((i) => {
    if (i+min === min || i+min === max) {
      return {
        value: i+min,
        label: i+min ,
      };
    }
    if(max - min <= 10) {
      return {
        value: i+min,
        label: "",
      };
    }
    return -1;
  }).filter((i) => i !== -1);

  return (
    <div className="slider-input">
      <Slider
        min={min}
        max={max}
        value={value}
        marks={marks}
        onChange={(e) => onChange(e.target.value)}
        valueLabelDisplay="auto"
        sx={{
          color: "white",
          ".MuiSlider-thumbColorPrimary": {
            backgroundColor: "#E85052",
          },
          ".MuiSlider-colorPrimary": {
            color: "white",
          },
          ".MuiSlider-rail": {
            opacity: 1,
          },
          ".Mui-active": {
            boxShadow: "0px 0px 0px 10px rgb(200 0 0 / 10%)",
          },
          ".MuiSlider-mark": {
            color: "#D9D9D9",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
          },
          ".MuiSlider-markLabel": {
            fontSize: "16px",
            color : "white",
          }
        }}
      />
      <h2 className="slider-value">{`${value} ${max === 100 ? "%" : ""}`}</h2>
    </div>
  );
};

export default SliderInput;
