// react
import { useState, useEffect } from "react";

// router
import { useNavigate } from "react-router-dom";

// css
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../css/Confirm.css";
import "../css/Game.css";

// div 100 vh
import Div100vh from "react-div-100vh";

// components
import Header from "../components/Header";
import InfoCard from "../components/InfoCard";
import TextAnswerCard from "../components/TextAnswerCard";
import TextAnswerInfoCard from "../components/TextAnswerInfoCard";
import SliderAnswerCard from "../components/SliderAnswerCard";
import MediaTextAnswerCard from "../components/MediaTextAnswerCard";
import MediaCard from "../components/MediaCard";
import ReviewCard from "../components/ReviewCard";
import Button from "../components/Button";
import { Confirm, confirm } from "../components/Confirm";
import { Carousel } from "react-responsive-carousel";

//audio
import ReactPlayer from "react-player";

// constants
import { MAX_DEVICE_WIDTH } from "../constants/constants";

// functions
import t from "../utils/translation";
import {
  saveGameState,
  clearGameState,
  getGameState,
  saveMediaProgress,
  clearMediaProgress,
  getMediaProgress,
  getUserName,
  getLanguage,
} from "../utils/storage";
import {
  convertSecondToMinuteSecond,
  convertDecimalToPercent,
} from "../utils/number";
import { checkAnswer, checkUserEmail } from "../utils/input";
import { sendReport } from "../utils/api";
//data
import { CARDS } from "../assets/data/cards";

const Game = () => {
  // states
  const [cards, setCards] = useState(CARDS);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isCurrentCardFlipped, setIsCurrentCardFlipped] = useState(false);
  const [points, setPoints] = useState(0);
  const [pointsHistory, setPointsHistory] = useState({});
  const [isMediaPlaying, setIsMediaPlaying] = useState(false);
  const [mediaTime, setMediaTime] = useState({
    total: {},
    current: "00:00",
    progress: 0,
  });
  const [isSendingReport, setIsSendingReport] = useState(false);
  const [isAnswerError, setIsAnswerError] = useState(false);
  const [isCoinWinAudioPlaying, setIsCoinWinAudioPlaying] = useState(false);

  // constants
  const minReward = Math.ceil(100 / cards.length) - 1;
  const maxReward = minReward + 2;

  // navigation
  const navigate = useNavigate();

  // carousel
  const carouselConfig = {
    showArrows: false,
    showThumbs: false,
    showStatus: false,
    showIndicators: false,
    swipeable: false,
    transitionTime: 600,
  };

  // actions
  const redirectToDesktopPageIfOnDesktop = async () => {
    if (window.innerWidth > MAX_DEVICE_WIDTH) navigate("/desktop");
  };

  const displayNextCard = async () => {
    if (currentCardIndex === cards.length - 1) {
      endGame();
      return;
    }

    if (currentCardIndex === cards.length - 2) {
      const result = await sendGameReport();
      if (!result) return;
    }

    const currentIndex = currentCardIndex + 1;
    const currentPoints = points + cards[currentCardIndex].reward;
    const isSlider = cards[currentCardIndex].type
      .toLowerCase()
      .includes("slider");

    if (isSlider && !("answer" in cards[currentCardIndex])) {
      const setAnswerToZero = () =>
        setCards(
          cards.map((card, index) => {
            if (index === currentCardIndex) {
              return {
                ...card,
                answer: 0,
              };
            }
            return card;
          })
        );

      confirm(
        t("confirmation"),
        t("areYouSureToChoose0"),
        t("yes"),
        t("no"),
        false,
        setAnswerToZero
      );
    } else {
      if (
        !isSlider &&
        cards[currentCardIndex].type.toLowerCase().includes("answer") &&
        (!("answer" in cards[currentCardIndex]) ||
          !checkAnswer(cards[currentCardIndex].answer || ""))
      ) {
        setIsAnswerError(true);
        return;
      }

      setIsAnswerError(false);
      if (currentCardIndex !== 0) {
        setIsCoinWinAudioPlaying(true);
      }

      setMediaTime({
        ...mediaTime,
        current: "00:00",
        progress: 0,
      });
      setIsMediaPlaying(false);

      // save the current game state in local storage
      saveGameState({ cards, currentIndex, currentPoints });

      // update the states
      setCurrentCardIndex(currentIndex);
      if (currentCardIndex !== 0) {
        const newPointsHistory = pointsHistory;
        newPointsHistory[currentCardIndex] = cards[currentCardIndex].reward;
        setPointsHistory(newPointsHistory);
        setPoints(currentPoints);
      }
      setIsCurrentCardFlipped(false);
    }
  };

  const displayPreviousCard = () => {
    if (currentCardIndex === 0) return;

    const currentIndex = currentCardIndex - 1;
    const currentPoints = points - (pointsHistory[currentIndex] || 0);

    // save the current game state in local storage
    saveGameState({ cards, currentIndex, currentPoints });

    // update the states
    setCurrentCardIndex(currentIndex);
    setPoints(currentPoints);
    setIsCurrentCardFlipped(false);
  };

  const sendGameReport = async () => {
    if (!checkUserEmail(cards[currentCardIndex].answer || "")) {
      setIsAnswerError(true);
      return false;
    }
    setIsAnswerError(false);
    const cardsToSend = cards
      .filter((card, i) => "answer" in card && card.type !== "review")
      .map((card) => {
        return {
          id: card.id,
          questionFR: t("card" + card.id, "FR"),
          question: t("card" + card.id),
          answer: card.answer,
        };
      });

    // array of the id's of the cards that the user answered with a slider
    const startArray = [3, 4, 9];
    const endArray = [28, 29, 30];

    const comparison = startArray.map((start, i) => {
      let difference = cards[endArray[i]].answer - cards[start].answer;
      if (difference > 0) difference = "+" + difference;

      return {
        start: start,
        end: endArray[i],
        startQuestionFR: t("card" + start, "FR"),
        endQuestionFR: t("card" + endArray[i], "FR"),
        startQuestion: t("card" + start),
        endQuestion: t("card" + endArray[i]),
        difference: difference,
      };
    });
    setIsSendingReport(true);
    const response = await sendReport({
      report: {
        answers: cardsToSend,
        answer: t("answer"),
        name: getUserName(),
        email: cards[currentCardIndex].answer,
        comparisons: comparison,
        comparison: t("comparison"),
        reportTitle: t("reportTitle"),
        yourReport: t("yourReport"),
        yourAnswers: t("yourAnswers"),
        yourGift: t("yourGift"),
        cardNumber: t("cardNumber"),
        cardsNumber: t("cardsNumber"),
        question: t("question"),
        questions: t("questions"),
        difference: t("difference"),
        language: getLanguage(),
      },
    });
    setIsSendingReport(false);

    if (response.status === "error") {
      confirm(t("error"), t("anErrorOccurred"), "Ok", t("no"), true);
      return;
    }

    return true;
  };

  const endGame = async () => {
    // show the confirmation popup
    confirm(
      t("success"),
      t("youAreGoingToBeRedirected"),
      "Ok",
      t("no"),
      true,
      clearDataAndRedirect,
      clearDataAndRedirect,
      clearDataAndRedirect
    );
  };

  const clearDataAndRedirect = () => {
    clearGameState();
    clearMediaProgress();
    navigate("/");
  };

  const handleCardFlip = () => {
    //clear media
    setMediaTime({
      ...mediaTime,
      current: "00:00",
      progress: 0,
    });
    setIsMediaPlaying(false);

    // generate a random reward
    const randomReward = Math.floor(Math.random() * maxReward) + minReward;
    setCards(
      cards.map((card, index) => {
        if (index === currentCardIndex)
          return { ...card, reward: randomReward };

        return card;
      })
    );

    // flip the card
    setIsCurrentCardFlipped(!isCurrentCardFlipped);
  };

  const validateAnswer = () => {
    const isSlider = cards[currentCardIndex].type
      .toLowerCase()
      .includes("slider");
    if (!checkAnswer(cards[currentCardIndex].answer || "", isSlider)) {
      setIsAnswerError(true);
      return;
    }
    setIsAnswerError(false);
    handleCardFlip();
  };

  // generate a random reward for the current card
  const onAnswerChange = (newAnswer) => {
    setCards(
      cards.map((card, index) => {
        if (index === currentCardIndex) {
          return { ...card, answer: newAnswer };
        }
        return card;
      })
    );
  };

  /**
   * If there is a game state, set the cards, current card index, and points to the game state's cards,
   * current index, and points.
   */
  const loadGameState = () => {
    const gameState = getGameState();
    const mediaProgress = getMediaProgress();

    if (!gameState) {
      return;
    }

    // update the states
    checkIfCardsChanged(gameState.cards);
    setCurrentCardIndex(gameState.currentIndex);
    setPoints(gameState.currentPoints);

    if (
      !mediaProgress ||
      !gameState.cards[gameState.currentIndex].type.includes("media")
    ) {
      return;
    }

    // update the media progress
    setMediaTime(mediaProgress);
  };

  const checkIfCardsChanged = (gameStateCards) => {
    const newCards = cards;

    newCards.map((card) => {
      gameStateCards.forEach((gameStateCard) => {
        if (card.id === gameStateCard.id) {
          if (card.type !== gameStateCard.type) {
            delete gameStateCard.answer;
            return gameStateCard;
          }
        }
      });
    });
    setCards(newCards);
  };

  /**
   * Set the state of the media player
   */
  const handleMediaPlay = () => {
    setIsMediaPlaying(!isMediaPlaying);
  };

  /**
   * Stop the media player
   */
  const handleMediaEnd = () => {
    setIsMediaPlaying(false);
  };

  /**
   * When the media is ready, set the total time of the media to the mediaTime.total array, and if the
   * current card is the same as the media, seek to the progress of the media.
   */
  const handleMediaReady = (id, player, total) => {
    const tempTotalArray = mediaTime.total;
    tempTotalArray[id] = convertSecondToMinuteSecond(total);

    setMediaTime({
      ...mediaTime,
      total: tempTotalArray,
    });

    // if the current card is the same as the one in the callback, seek to the progress of the media
    // seek is used to set the current time of the media
    if (cards[currentCardIndex].id === id) {
      // seek to the progress of the media in decimal between 0 and 1
      player.seekTo(mediaTime.progress / 100);
    }
  };

  /**
   * When the media is playing, update the progress and the current time.
   */
  const handleMediaProgress = (currentProgress) => {
    if (isMediaPlaying) {
      const progress = convertDecimalToPercent(currentProgress.played);
      const current = convertSecondToMinuteSecond(
        currentProgress.playedSeconds
      );

      // save the progress of the media in local storage
      saveMediaProgress({ ...mediaTime, progress, current });

      // update the states
      setMediaTime({ ...mediaTime, progress, current });
    }
  };

  // use effect - redirect if not mobile
  useEffect(() => {
    redirectToDesktopPageIfOnDesktop();
    window.addEventListener(
      "resize",
      () => {
        redirectToDesktopPageIfOnDesktop();
      },
      false
    );

    loadGameState();

    return () => {
      window.removeEventListener(
        "resize",
        () => {
          redirectToDesktopPageIfOnDesktop();
        },
        false
      );
    };
  }, []);

  return (
    <>
      <Div100vh>
        <Header phase={cards[currentCardIndex].phase} points={points} />
        <Confirm />
        <div className="game-container">
          <div className="game-card-div">
            {currentCardIndex !== 0 && currentCardIndex < cards.length - 2 && (
              <button
                className="game-previous-button"
                onClick={displayPreviousCard}
              >
                {"<"}
              </button>
            )}
            <Carousel selectedItem={currentCardIndex} {...carouselConfig}>
              {cards.map((card, i) => {
                if (card.type === "info")
                  return (
                    <InfoCard
                      key={i}
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      onCardFlip={handleCardFlip}
                    />
                  );
                if (card.type === "infoLink")
                  return (
                    <InfoCard
                      key={i}
                      {...card}
                      link={"https://www.capsurlaconfiance.ch"}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      onCardFlip={handleCardFlip}
                    />
                  );
                if (card.type === "textAnswer")
                  return (
                    <TextAnswerCard
                      key={"card" + card.id}
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      onCardFlip={handleCardFlip}
                      onAnswerChange={onAnswerChange}
                    />
                  );

                if (card.type === "textAnswerInfo")
                  return (
                    <TextAnswerInfoCard
                      key={"card" + card.id}
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      onAnswerChange={onAnswerChange}
                    />
                  );

                if (card.type.includes("sliderAnswer"))
                  return (
                    <SliderAnswerCard
                      key={"card" + card.id}
                      maxSliderValue={
                        card.type.includes("sliderAnswer100") ? 100 : 10
                      }
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      onCardFlip={handleCardFlip}
                      onAnswerChange={onAnswerChange}
                    />
                  );
                if (card.type === "mediaAudioTextAnswer")
                  return (
                    <MediaTextAnswerCard
                      key={"card" + card.id}
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      isPlaying={isMediaPlaying && i === currentCardIndex}
                      currentTime={mediaTime.current}
                      totalTime={mediaTime.total[cards[currentCardIndex].id]}
                      progress={mediaTime.progress}
                      onAnswerChange={onAnswerChange}
                      onPlay={handleMediaPlay}
                      onEnd={handleMediaEnd}
                      onReady={handleMediaReady}
                      onProgress={handleMediaProgress}
                    />
                  );
                if (card.type === "mediaAudio")
                  return (
                    <MediaCard
                      key={"card" + card.id}
                      {...card}
                      isCardFlipped={
                        isCurrentCardFlipped && i === currentCardIndex
                      }
                      isPlaying={isMediaPlaying && i === currentCardIndex}
                      onCardFlip={handleCardFlip}
                      currentTime={mediaTime.current}
                      totalTime={mediaTime.total[cards[currentCardIndex].id]}
                      progress={mediaTime.progress}
                      onPlay={handleMediaPlay}
                      onEnd={handleMediaEnd}
                      onReady={handleMediaReady}
                      onProgress={handleMediaProgress}
                    />
                  );

                if (card.type === "review")
                  return (
                    <ReviewCard
                      key={"card" + cards[currentCardIndex].id}
                      {...card}
                      totalPoints={points}
                      isCardFlipped={isCurrentCardFlipped}
                      onCardFlip={handleCardFlip}
                      onAnswerChange={onAnswerChange}
                    />
                  );

                return null;
              })}
            </Carousel>
          </div>
          <div className="game-body">
            {isAnswerError && (
              <p className="game-body-field-error">
                {t("pleaseEnterAValidAnswer")}
              </p>
            )}
            <div className="game-body-buttons">
              {cards[currentCardIndex].type === "textAnswerInfo" &&
                !isCurrentCardFlipped && (
                  <Button text={t("validateAnswer")} onClick={validateAnswer} />
                )}

              {cards[currentCardIndex].type === "mediaAudioTextAnswer" &&
                !isCurrentCardFlipped && (
                  <Button text={t("next")} onClick={handleCardFlip} />
                )}

              {isCurrentCardFlipped && (
                <Button
                  text={t(
                    currentCardIndex === cards.length - 1 ? "end" : "next"
                  )}
                  onClick={displayNextCard}
                  loading={isSendingReport}
                />
              )}
            </div>
            <ReactPlayer
              url={require(`../assets/audio/win-coin.m4a`)}
              playing={isCoinWinAudioPlaying}
              volume={0.5}
              onEnded={() => setIsCoinWinAudioPlaying(false)}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </Div100vh>
    </>
  );
};

export default Game;
