// react
import { useEffect, useState } from "react";

// router
import { useNavigate } from "react-router-dom";

// css
import "../css/Home.css";

// components
import Button from "../components/Button";

// constants
import { MAX_DEVICE_WIDTH } from "../constants/constants";

// storage
import { saveLanguage, getLanguage } from "../utils/storage";

// functions
import t from "../utils/translation";

// images
import translateIcon from "../assets/img/translate.svg";
import frenchFlagIcon from "../assets/img/french-flag.jpg";
import germanFlagIcon from "../assets/img/german-flag.png";
import englishFlagIcon from "../assets/img/english-flag.png";

const Home = () => {
  // route
  const navigate = useNavigate();
  const [gameStarted, setGameStarted] = useState(false);
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  // actions
  const redirectToDesktopPageIfOnDesktop = () => {
    if (window.innerWidth > MAX_DEVICE_WIDTH) navigate("/desktop");
  };

  const startGame = () => {
    setGameStarted(true);
    // add css class to element
    document.querySelector(".home-container").classList.add("zoom-in-lighthouse");
    document.querySelector(".translate-container").classList.add("hidden");
    setTimeout(() => navigate("/menu"), 3000);
  };

  const toggleLanguageSelector = () => setShowLanguageSelector(!showLanguageSelector);
  const closeLanguageSelector = () => setShowLanguageSelector(false);

  const changeLanguage = (language) => {
    if (language === getLanguage()) return;
    saveLanguage(language);
    // reload page to update language
    window.location.reload();
  }

  // use effect - redirect if not mobile
  useEffect(() => {
    redirectToDesktopPageIfOnDesktop();
    window.addEventListener(
      "resize",
      () => redirectToDesktopPageIfOnDesktop(),
      false
    );

    return () => {
      window.removeEventListener(
        "resize",
        () => {
          redirectToDesktopPageIfOnDesktop();
        },
        false
      );
    };
  }, []);

  return (
    <>
        <div className={`home-container  ${showLanguageSelector ? "blured" : ""}`} onClick={closeLanguageSelector}>
          <div className="home-header">
            <h1>{t("homeTitle")}</h1>
            <h5>{t("by")} Mélanie Cotting & Quentin Bays</h5>
          </div>
          <Button text={t("startGame")} onClick={startGame} />
          <div className="home-footer">
            <p className="cas">{t("homeDescription")}</p>
            <p className="cas">{t("homeMention")}</p>
            <p className="by-support">{t("applicationBy")}
              <a href="https://support-informatique.ch" target="_blank">
                Support-Informatique
              </a>
            </p>
          </div>
        </div>
        <div className={`translate-container ${showLanguageSelector ? "displayed" : ""}`} onClick={toggleLanguageSelector}>
          <img src={frenchFlagIcon} alt="" className="flag" onClick={() => changeLanguage("FR")} />
          <img src={germanFlagIcon} alt="" className="flag" onClick={() => changeLanguage("DE")} />
          <img src={englishFlagIcon} alt="" className="flag" onClick={() => changeLanguage("EN")} />
          <img src={translateIcon} alt="" />
        </div>
    </>
  );
};

export default Home;
