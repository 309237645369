// react
import { useState, useEffect } from "react";

// router
import { useNavigate } from "react-router-dom";

// css
import "../css/Menu.css";

// components
import Modal from "react-modal";
import Button from "../components/Button";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";

// constants
import { MAX_DEVICE_WIDTH } from "../constants/constants";

// functions
import t from "../utils/translation";
import { saveUserName } from "../utils/storage";
import { checkUserName } from "../utils/input";

const Menu = () => {
  // navigation
  const navigate = useNavigate();

  // states
  const [name, setName] = useState("");
  const [gameStarted, setGameStarted] = useState(false);
  const [error, setError] = useState(false);
  const [isConditions, setIsConditions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // actions
  const redirectToDesktopPageIfOnDesktop = () => {
    if (window.innerWidth > MAX_DEVICE_WIDTH) navigate("/desktop");
  };

  const startGame = () => {
    // check regex on "name". Must contain at least 3 letters
    if (!name.match(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/))
      return setError(true);

    saveUserName(name);
    setGameStarted(true);

    setTimeout(() => {
      navigate("/game");
    }, 1500);
  };

  // use effect - redirect if not mobile
  useEffect(() => {
    redirectToDesktopPageIfOnDesktop();
    window.addEventListener(
      "resize",
      () => {
        redirectToDesktopPageIfOnDesktop();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "resize",
        () => {
          redirectToDesktopPageIfOnDesktop();
        },
        false
      );
    };
  }, []);

  return (
    <div className={`menu-container ${gameStarted ? "zoom-in-menu" : ""}`}>
      <div className={`menu-header ${gameStarted ? "fade-menu-out" : ""}`}>
      {!isModalOpen && <h3>{t("menuTitle")}</h3>}
      </div>
      <div className={`menu-body ${gameStarted ? "fade-menu-out" : ""}`}>
        {!isModalOpen && (
          <>
            <Input placeholder={t("firstname")} onChange={setName} />
            <a
              className="read-conditions"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              {t("readConditions")}
            </a>
            <Checkbox
              text={t("iAcceptConditions")}
              checked={isConditions}
              onChange={setIsConditions}
            />

            <p className="error" style={{ display: error ? "block" : "none" }}>
              {t("pleaseEnterAValidName")}
            </p>

            <Button
              text={t("startGame")}
              onClick={startGame}
              disabled={!isConditions}
            />
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <p>
          {t("generalConditions")}
          <a
            href="https://capsurlaconfiance.ch/termes-et-conditions/"
            target="_blank"
          >
            {t("generalConditionsLink")}
          </a>
          {t("privacyPolicy")}
          <a
            href="https://capsurlaconfiance.ch/politique-de-confidentialite/"
            target="_blank"
          >
            {t("privacypPolicyLink")}
          </a>
        </p>
        <Button
          text={t("close")}
          onClick={() => {
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Menu;
