// router
import { useNavigate } from "react-router-dom";

// react
import { useEffect } from "react";

// css
import "../css/Desktop.css";

// images
import qrcode from "../assets/img/qr-code.png";


const Desktop = () => {
  // navigation
  const navigate = useNavigate();

  // actions
  const redirectToHomePageIfMobile = () => {
    if (window.innerWidth <= 768) {
      navigate("/");
    }
  };

  // use effect - redirect if not mobile
  useEffect(() => {
    redirectToHomePageIfMobile();
    window.addEventListener(
      "resize",
      () => {
        redirectToHomePageIfMobile();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "resize",
        () => {
          redirectToHomePageIfMobile();
        },
        false
      );
    };
  }, []);

  return (
    <div className="desktop-container">
      <div className="desktop-header">
        <h1>Veuillez utiliser un téléphone pour jouer au jeu !</h1>
        <h5>Jeu par Mélanie Cotting & Quentin Bays</h5>
        <img src={qrcode} alt="qrcode" />
        <p>Site web par <a href="https://support-informatique.ch">Support-Informatique</a></p>
      </div>
    </div>
  );
};

export default Desktop;
