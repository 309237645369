import { getLanguage, saveLanguage } from "./storage";

import FR from "../assets/data/fr";
import DE from "../assets/data/de";
import EN from "../assets/data/en";

const translations = {
  FR,
  DE,
  EN,
};

const language = getLanguage();

// translate
const t = (key, l) => {
  return translations[l || language][key] || key;
};

export default t;
