const DE = {

    // HOME
    homeTitle : "Fähig oder nicht  ? ", 
    homeDescription : "Zertifikat in Bildungsneurowissenschaften bei Cherine Fahim PhD",
    homeMention : "Übersetzung von Nicole Estèbe und Shaylee Cotting",
    
    // MENU
    menuTitle : "Bist du bereit, die Weichen auf Vertrauen zu stellen, um besser zu lernen ?",
    pleaseEnterAValidName : "Gib einen gültigen Namen ein",
    generalConditions : "Mit der Nutzung der App akzeptierst du die ",
    generalConditionsLink : "Teilnahmebedingungen. ",
    privacyPolicy : "ie App speichert deine Daten, damit du dein Spiel von einem Tag zum anderen fortsetzen kannst und am Ende des Spiels deine Bilanz erhältst. Sie können nur von den Autoren eingesehen werden, und zwar ausschließlich für interne und anonyme Zwecke, um die Anwendung zu verbessern. Weitere Informationen findest du in unserer ",
    privacypPolicyLink : "Datenschutzerklärung. ",
    iAcceptConditions: "Ich akzeptiere die allgemeinen Teilnahmebedingungen",
    readConditions: "Die allgemeinen Teilnahmebedingungen lesen",
    close: "Schließen",

    // INTRO
    card0 : "Mit 100 Erfahrungspunkten kannst du die Schatztruhe öffnen !",

    // PHASE 1
    card1 : "Wähle eine Situation, in der du dich gerne selbstbewusster fühlen möchtest.",
    card2 : "Welcher Gedanke kommt dir in Bezug auf diese Situation automatisch in den Sinn ?",
    card3 : "Von 0 bis 10: Wie wahr erscheint dir dieser Gedanke ?",
    card4 : "Von 0 bis 10: Wie sehr stresst dich die Situation zurzeit ?",
    card5 : "Wo spürst du den Stress in deinem Körper ?",
    card6 : "Welches Verhalten ergibt sich für dich aus dieser Situation ?",
    card7 : "Welche Auswirkungen hat dieses Verhalten ?",
    card8 : "Was willst du an Stelle dieser Situation ? Was ist dein Ziel ?",
    card9 : "Von 0 bis 100 %: Wie gut fühlst du dich zurzeit in der Lage, dein Ziel zu erreichen ?",

    // PHASE 2
    card10: "Alarmsystem", //X
    card11: "Atem-Herausforderung", //X
    card12: "Herausforderung der wandernden Viertelstunde", //X
    card13: "Emotionen-Herausforderung", //X
    card14: "Rekonfigurationsherausforderung", //X

    // PHASE 3
    card15 : "Ist dein Denken in der Situation, in der du dich befindest, realistisch ?",
    cardInfo15 : "Wir sehen die Dinge nicht so, wie sie sind, sondern interpretieren sie auf der Grundlage unserer Erfahrungen.",
    card16 : "Beruht dein Denken auf Fakten oder auf Gefühlen ?",
    cardInfo16 : "Oft sind in Situationen, die starke Emotionen auslösen, die Kommunikationskanäle vom Verstand verschleiert. ",
    card17 : "Sind deine Gedanken richtig ?",
    cardInfo17 : "Es gibt Beweise dafür, dass der Gedanke nicht immer wahr ist.",
    card18 : "Siehst du die Situation nur schwarz-weiß oder sind auch Nuancen möglich ?",
    cardInfo18 : "Häufig ziehen wir aufgrund von Emotionen zu schnell Schlussfolgerungen.",
    card19 : "Was ist in deiner konkreten Situation das Schlimmste, was dir passieren kann ?",
    cardInfo19 : "Selbst der schlimmste Fall kann gemeistert werden !",
    card20 : "Wie könntest du deine Situation neu interpretieren ?",
    cardInfo20 : "Deine Leistungen widerspiegeln nicht deinen Wert.",

    // PHASE 4
    card21: "Die exekutiven Funktionen", //X
    card22: "Die Aufmerksamkeit", //X
    card23: "Die Merkfähigkeit", //X
    card24: "Die Inhibition", //X
    card25: "Die Planung", //X

    // PHASE 5
    card26: "Wähle eine Aktion", //X
    
    // PHASE 6
    card27: "Mit 100 Erfahrungspunkten kannst du die Schatztruhe öffnen !",
    
    // REVIEW
    card28 : "Von 0 bis 10: Wie stark ist dein automatisches Denken jetzt noch ?",
    card29 : "Von 0 bis 10: Wie sehr stresst dich die Situation zurzeit noch ?",
    card30 : "Von 0 bis 100 %: Wie gut fühlst du dich jetzt in der Lage, dein Ziel zu erreichen ?",
    card32 : "Möchtest du deine Situation besser entblocken ? Finde die richtige Begleitung für dich auf",

    // REVIEW
    congratulation: "Gratulation zu deiner Arbeit !",  //X
    encourage: "Ich ermutige dich, mit anderen Situationen noch einmal zu spielen, wenn dir dieser Teil etwas gebracht hat", //X
    accumulate: "Du hast angesammelt", //X
    enterYourEmailAddress: "Gib deine E-Mail-Adresse ein",
    toReceiveYourGift: "Um deinen Bericht und ein Geschenk zu erhalten !",
    emailAddress: "E-Mail-Adresse", //X

    // REPORT
    reportTitle : "Hier ist dein Bericht/Geschenk",
    yourReport : "Dein Bericht",
    yourGift : "Dein Geschenk",
    yourAnswers : "Ihre Antworten",
    cardNumber : "Nr. Karte",
    cardsNumber : "Nr. Karten",
    question : "Frage",
    questions : "Fragen",
    answer : "Antwort",
    comparison : "Vergleich",
    difference : "Unterschied",


    // GENERAL
    applicationBy: "Anwendung nach", //X
    by: "Von", //X
    startGame: "Starte das Spiel", //X
    firstname: "Vorname", //X
    lastname: "Name", //X
    phase: "Phase", //X
    next: "Nächster", //X
    youWon: "du gewinnst", //X
    stars: "Stern(e)", //X
    typeYourAnswer: "Zwischen deiner Antwort", //X
    validateAnswer: "Bestätige die Antwort", //X
    pleaseEnterAValidAnswer: "Gib eine gültige Antwort ein", //X
    lastStep: "Letzter Schritt", //X
    end: "Ende", //X
    introduction: "Einleitung", //X
    review: "Bilanz", //X
    yes : "Ja", //X
    no : "Nein", //X
    confirmation : "Bestätigung", //X
    areYouSureToChoose0 : "Bist du sicher, dass du 0 als Antwort wählst ?", //X
    success: "Erfolg", //X
    error: "Fehler", //X
    youAreGoingToBeRedirected: "Du wirst zur Startseite weitergeleitet, wenn du auf OK klickst", //X
    anErrorOccurred: "Es ist ein Fehler aufgetreten", //X

}

export default DE;