// react
import { useEffect, useState } from "react";

// react-router-dom
import { BrowserRouter as RouterDom, Routes, Route } from "react-router-dom";
// pages
import Home from "../pages/Home";
import Game from "../pages/Game";
import Menu from "../pages/Menu";
import Desktop from "../pages/Desktop";

const Router = () => {
  return (
    <div className="App">
      <RouterDom key="router">
        <Routes>
          <Route key="/" path="/" exact element={<Home />} />
          <Route key="/menu" path="/menu" element={<Menu />} />
          <Route key="/game" path="/game" exact element={<Game />} />
          <Route key="/desktop" path="/desktop" exact element={<Desktop />} />
          <Route key="*" path="*" element={<Home />} />
        </Routes>
      </RouterDom>
    </div>
  );
};

export default Router;
