// css
import "../css/Checkbox.css";

const Checkbox = ({ text, onChange, checked = false }) => {
  return (
    <label class="checkbox">
      {text}
      <input type="checkbox"  checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <span class="checkmark"></span>
    </label>
  );
};

export default Checkbox;
