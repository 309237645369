/**
 * Display an image on the frontside of the card
 * Show a media on the backside of the card
 */

// css
import "../css/Card.css";

// images
import star from "../assets/img/star.svg";

// components
import AudioPlayer from "./AudioPlayer";

// functions
import t from "../utils/translation";
import { getLanguage } from "../utils/storage";

const MediaCard = ({
  id,
  reward,
  image,
  isCardFlipped,
  onCardFlip,
  isAudio = true,
  isPlaying,
  currentTime,
  totalTime,
  progress,
  onPlay,
  onEnd,
  onReady,
  onProgress,
}) => {
  const language = getLanguage();

  // states
  const cardImage = require("../assets/cards/" + (image || id + ".png"));

  return (
    <div className={`flip-card ${isCardFlipped ? "flip-card-flipped" : ""}`}>
      <div className="flip-card-container">
        <div
          className="flip-card-front"
          onClick={onCardFlip}
          style={{ backgroundImage: `url(${cardImage})` }}
        ></div>
        <div className="flip-card-back">
          <h3>{t("card" + id)}</h3>
          <hr />
          <AudioPlayer
            src={require(`../assets/audio/${id}-${language}.m4a`)}
            isPlaying={isPlaying}
            currentTime={currentTime}
            totalTime={totalTime}
            progress={progress}
            onPlay={onPlay}
            onEnd={onEnd}
            onReady={(player, total ) => onReady(id, player, total)}
            onProgress={onProgress}
          />
          <div className="flip-card-score">
            <h4>{`${t("youWon")} ${reward} ${t("stars")}`}</h4>
            <img src={star} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCard;
