/**
 * Display an image on the frontside and some info
 */

// css
import "../css/Card.css";

// functions
import t from "../utils/translation";

const InfoCard = ({ id, image, link, isCardFlipped, onCardFlip }) => {
  // states
  const cardImage = require("../assets/cards/" + (image || id + ".png"));
  return (
    <div className={`flip-card ${isCardFlipped ? "flip-card-flipped" : ""}`}>
      <div className="flip-card-container info-card">
        <div
          className="flip-card-front"
          onClick={onCardFlip}
          style={{ backgroundImage: `url(${cardImage})` }}
        ></div>
        <div className="flip-card-back">
          <hr />
          <h3>{t("card" + id)}</h3>
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer" className="flip-card-info-link">
              {link.replace(/^https?:\/\//, "")}
            </a>
          )}
          <hr />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
