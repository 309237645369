// router
import Router from "./router/Router";

// custom css
import "./css/App.css";

function App() {
  return (
    <Router/>
  );
}

export default App;
